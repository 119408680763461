.logo-container {
  height: 200px;
  width: 200px;
  position: fixed;
  left: 0;
  top: 0;

  z-index: 2;
}
.logo {
  height: 100%;
}
.header-container {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  height: 120px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.bg {
  background-color: rgba(206, 212, 218, 0.1);
}
.micro-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px;
  background-color: white;
  z-index: 1;
  border-right: 1px solid #ced4da;
}
.micro-sidebar-content {
  width: 100%;
  height: 100%;
  margin-top: 200px;
}
.micro-menu-button {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  margin-bottom: 1rem;
}
.menu-text {
  cursor: pointer;
}
.menu-text:hover {
  text-decoration: underline;
}

.text-container {
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.tab-darker-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.photo-container {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: fit-content;
  padding: 1rem;
}
