.details-value-text {
  font-size: 18px;
  min-height: 27px;
  word-break: break-word;
}
.details-label-text {
  font-weight: 600;
}

.event-details-number-mobile {
  display: none;
}

@media (max-width: 576px) {
  .event-details-number {
    font-size: 2rem !important;
    align-self: center;
  }
}

@media (max-width: 1150px) {
  .event-number-title {
    font-size: 2rem !important;
    margin-top: 0.5rem !important;
  }
}

.lynx-mentions-input__input {
  border: 1px solid #ced4da;
  border-radius: 4px;
  font: inherit;
  padding: 0.375rem 0.75rem;
}

.lynx-mentions-input__control {
  padding: 0.375rem 0.75rem;
}
.lynx-mentions-input__input:focus {
  border-color: #80bdff;
  border-radius: 4px;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: none;
}
.lynx-mentions-input {
  min-height: 60px;
}
.email-body-container {
  max-height: 700px;
  overflow: auto;
  border: 1px solid #ced4da;
  margin-top: 0.5rem;
}

.lynx-mentions-input__suggestions {
  border: 1px solid #f8f9fa;
  box-shadow: 0 0 8px #f8f9fa;
  width: max-content;
  max-height: 300px;
  overflow: scroll;
}

.lynx-mentions-input__suggestions__item {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0.5rem;
}

.lynx-mentions-input__suggestions__item--focused {
  background-color: rgba(0, 0, 0, 0.04);
}
