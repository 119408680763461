.map-legend-container {
  position: fixed;
  left: 200px;
  bottom: 0;
  width: 100%;

  height: 40px;
  display: flex;
  justify-content: space-around;
}

.map-legend {
  width: fit-content;
  height: 100%;
  background-color: white;
  margin-right: 200px;
  padding-bottom: 2px;
}

.map-legend-sidebar {
  margin-right: 400px !important;
}

.map-sidebar-header {
  min-height: 50px;
  padding-left: 0.7rem;
  padding-top: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.map-sidebar-right {
  position: fixed;
  width: 260px;

  bottom: 0;
  top: 50px;

  border-left: 1px solid #ced4da;
  background-color: white;
  right: 0;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #ced4da;
}

.map-header-overlay {
  position: fixed;
  top: 140px;
  z-index: 1000;
  right: 40px;
}

.asset-map {
  top: 168px !important;
}

.asset-map-top {
  top: 260px !important;
}

.map-header-overlay.with-sidebar {
  right: 290px;
}

.map-header-content {
  position: fixed;
  display: flex;
  width: 250px;
  height: 50px;
  right: 0;

  justify-content: center;

  border: 2px solid #f3a738;
}

.mapboxgl-ctrl-top-left {
  margin-left: 50px;
}

.map-sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  bottom: 0;
  top: 100px;
  border-left: 1px solid #ced4da;
  right: 0;
}

@media (max-width: 900px) {
  .map-header-overlay {
    left: 0;
  }
}

@media (max-width: 900px) {
  .map-legend {
    width: fit-content;
    height: 40px;
    background-color: white;
    margin-right: 0;
    padding-bottom: 2px;
  }

  .map-legend-container {
    left: 0px;
  }
}
