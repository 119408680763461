@font-face {
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 400;
  src: local("DIN Pro Regular"), local("DINPro-Regular"),
    url("./fonts/din/DinProRegular.woff") format("woff"),
    url("./fonts/din/DinProRegular.ttf") format("truetype");
}

@font-face {
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 500;
  src: local("DIN Pro Medium"), local("DINPro-Medium"),
    url("./fonts/din/DinProMedium.woff") format("woff"),
    url("./fonts/din/DinProMedium.ttf") format("truetype");
}

@font-face {
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 700;
  src: local("DIN Pro Bold"), local("DINPro-Bold"),
    url("./fonts/din/DinProBold.woff") format("woff"),
    url("./fonts/din/DinProBold.ttf") format("truetype");
}

@font-face {
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 300;
  src: local("DIN Pro Light"), local("DINPro-Light"),
    url("./fonts/din/DinProLight.woff") format("woff"),
    url("./fonts/din/DinProLight.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"),
    url("./styles/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"),
    url("./styles/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"),
    url("./styles/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"),
    url("./styles/fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./styles/fonts/Inter-VariableFont_opsz\,wght.ttf") format("truetype");
}
