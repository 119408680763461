$wyloo_white: #ffffff;
$wyloo-black: #322c2c;
$wyloo-grey: #beb7b3;
$wyloo-light-grey: #e4e1dc;
$wyloo-lighter-grey: #f8f8f6;
.wyloo-microsite-container {
  font-family: "Poppins", sans-serif;
  position: fixed;
  height: 100%;
  overflow-y: auto !important;
  scrollbar-width: auto;
  width: 100%;
  padding-top: 50px;
  .mapboxgl-popup-content {
    background-color: $wyloo-lighter-grey;
    color: $wyloo_black;
  }
  .mapboxgl-popup-close-button {
    color: $wyloo_black;
  }
}

.recharts-text {
  color: $wyloo_white !important;
}
.micro-sidebar-content-wyloo {
  width: 100%;
  height: 100%;
  margin-top: 80px;
}

.wyloo-colors {
  background-color: $wyloo_light_grey !important;
  color: $wyloo_black !important;
}

.mapboxgl-popup-content {
  background-color: $wyloo_white !important;
  color: $wyloo_white;
}
.login-button-wyloo {
  position: fixed !important;
  right: 50px;
  color: $wyloo_black !important;
  top: 0;
}
.avatar-wyloo {
  position: fixed !important;
  right: 60px;
  background-color: $wyloo-black !important;
  color: $wyloo-lighter-grey !important;
  top: 4.5px;
}
.contact-button-wyloo {
  position: fixed !important;
  right: 120px;
  color: $wyloo_black !important;
  top: 0;
}

.lynx-logo-wyloo {
  position: fixed !important;
  right: -120px;

  top: -127px;
}
.wyloo-navbar {
  height: 50px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  color: $wyloo_black;
  background-color: $wyloo-light-grey;
  z-index: 2;
  border-bottom: 1px solid $wyloo-black;
}
// .recharts-default-tooltip {
//   overflow-y: auto;
//   .recharts-tooltip-item-list {
//     height: 500px;
//   }
// }
.header-container-wyloo {
  position: relative;
  width: 100%;
  height: 200px;
}
.header-container-wyloo img {
  width: 100%;
  object-fit: cover;
  display: block;
  height: 200px;
}
.header-container-wyloo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  );
  z-index: 1;
}
.portal-badge {
  border-radius: 25px !important;
  padding: 10px !important;
}
.wyloo-header-text {
  position: absolute;

  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: "100&";
  z-index: 2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Text shadow for better visibility */
  padding: 10px;

  border-radius: 5px;
}
.lynx-logo-container {
  position: fixed;
  right: 0;
  width: 40px;
  height: 50px;
  top: 0;
}
.wyloo-text-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.wyloo-text-section {
  border: 1px solid rgba(0, 0, 0, 0.8);
  background-color: $wyloo-lighter-grey;
  padding: 1rem;
  border-radius: 25px;
}
.wyloo-button-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}
.wyloo-menu-button {
  display: flex;
  width: 90px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.8);
  background-color: $wyloo-lighter-grey;
  padding: 1rem;
  border-radius: 25px;
  &:hover {
    color: $wyloo-lighter-grey;
    background-color: $wyloo-black;
    cursor: pointer;
  }
}
.wyloo-menu-button-disabled {
  display: flex;
  width: 90px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.8);

  background-color: $wyloo-grey;
  padding: 1rem;
  border-radius: 25px;
}

.wyloo-map-legend {
  height: 40px;
  width: "100%";
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}
.disabled-chip {
  background-color: $wyloo-grey !important;
}
